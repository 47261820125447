<div class="tracker-bar" [class.transitions]="transitionsEnabled">
  <div class="stage {{ stages.waiting.state }}">Sent</div>
  <div class="divider left-{{ stages.waiting.state }} right-{{ stages.pending.state }}"></div>
  <div class="stage {{ stages.pending.state }}">Pending</div>
  <div class="divider left-{{ stages.pending.state }} right-{{ stages.soon.state }}"></div>
  <div class="stage {{ stages.soon.state }}">Soon</div>
  <div class="divider left-{{ stages.soon.state }} right-{{ stages.next.state }}"></div>
  <div class="stage {{ stages.next.state }}">Next block</div>
  <div class="divider left-{{ stages.next.state }} right-{{ stages.confirmed.state }}"></div>
  <div class="stage {{ stages.confirmed.state }}">Confirmed</div>
</div>