<div class="mobile-wrapper">
  <div class="mobile-container">
    <div class="panel">
      <div class="nav-header">
        @if (enterpriseInfo?.header_img) {
          <a class="d-flex" [routerLink]="['/' | relativeUrl]">
            <img *ngIf="enterpriseInfo.header_img" [src]="enterpriseInfo?.header_img" alt="enterpriseInfo.title" height="42px">
          </a>
        } @else if (enterpriseInfo?.img || enterpriseInfo?.imageMd5) {
          <a [routerLink]="['/' | relativeUrl]">
            <img [src]="enterpriseInfo.img || '/api/v1/services/enterprise/images/' + enterpriseInfo.name + '/logo?imageMd5=' + enterpriseInfo.imageMd5" class="subdomain_logo" [class]="{'rounded': enterpriseInfo.rounded_corner}">
          </a>
          <div class="vertical-line"></div>
        }
        @if (!enterpriseInfo?.header_img) {
          <a [routerLink]="['/' | relativeUrl]">
            <app-svg-images *ngIf="!officialMempoolSpace" name="mempoolSpace" style="width: 144px; height: 36px" viewBox="0 0 500 126" width="500" height="126" class="mempool-logo"></app-svg-images>
            <app-svg-images *ngIf="officialMempoolSpace" name="officialMempoolSpace" style="width: 144px; height: 36px" width="500" height="126" viewBox="0 0 500 126"></app-svg-images>
          </a>
        }

        @if (enterpriseInfo?.header_img || (!enterpriseInfo?.img && !enterpriseInfo?.imageMd5)) {
          <div [ngSwitch]="network" class="network-label" [class.hide-name]="enterpriseInfo?.header_img">
            <span *ngSwitchCase="'signet'" class="network signet"><span class="name">Bitcoin Signet</span><app-svg-images name="signet" width="35" height="35" viewBox="0 0 65 65" style="display: inline-block" class="mainnet ml-2"></app-svg-images></span>
            <span *ngSwitchCase="'testnet'" class="network testnet"><span class="name">Bitcoin Testnet3</span><app-svg-images name="testnet" width="35" height="35" viewBox="0 0 65 65" style="display: inline-block" class="mainnet ml-2"></app-svg-images></span>
            <span *ngSwitchCase="'testnet4'" class="network testnet"><span class="name">Bitcoin Testnet4</span><app-svg-images name="testnet4" width="35" height="35" viewBox="0 0 65 65" style="display: inline-block" class="mainnet ml-2"></app-svg-images></span>
            <span *ngSwitchCase="'liquid'" class="network liquid"><span class="name">Liquid</span><app-svg-images name="liquid" width="35" height="35" viewBox="0 0 125 125" style="display: inline-block" class="mainnet ml-2"></app-svg-images></span>
            <span *ngSwitchCase="'liquidtestnet'" class="network liquidtestnet"><span class="name">Liquid Testnet</span><app-svg-images name="liquidtestnet" width="35" height="35" viewBox="0 0 125 125" style="display: inline-block" class="mainnet ml-2"></app-svg-images></span>
            <span *ngSwitchDefault class="network mainnet"><span class="name">Bitcoin</span><app-svg-images name="bitcoin" width="35" height="35" viewBox="0 0 65 65" style="display: inline-block" class="mainnet ml-2"></app-svg-images></span>
          </div>
        }
      </div>
      <div class="field">
        <div class="label" i18n="shared.transaction">Transaction</div>
        <div class="value">
          <app-truncate [text]="txId" [lastChars]="12" [link]="['/tx/' | relativeUrl, txId]">
            <app-clipboard [text]="txId"></app-clipboard>
          </app-truncate>
        </div>
      </div>
    </div>
      <div class="blockchain-wrapper" [style]="{ height: blockchainHeight * 1.16 + 'px' }">
        <app-clockchain [height]="blockchainHeight" [width]="blockchainWidth" mode="none"></app-clockchain>
      </div>
    <div class="panel">
      @if (replaced) {
        <div class="alert-replaced" role="alert">
          <span i18n="transaction.rbf.replacement|RBF replacement">This transaction has been replaced by:</span>
          <app-truncate [text]="latestReplacement" [lastChars]="12" [link]="['/tracker/' | relativeUrl, latestReplacement]"></app-truncate>
        </div>
      } @else {
        <div class="tracker-bar">
          <app-tracker-bar [stage]="trackerStage"></app-tracker-bar>
        </div>
      }
      <div class="data">
        @if (tx && !tx.status?.confirmed && mempoolPosition?.block != null) {
          <div class="field narrower mt-2">
            <div class="label" i18n="transaction.first-seen|Transaction first seen">First seen</div>
            <div class="value">
              @if (transactionTime > 0) {
                <i><app-time kind="since" [time]="transactionTime" [fastRender]="true" [showTooltip]="true"></app-time></i>
              } @else {
                <span class="skeleton-loader" style="max-width: 50%;"></span>
              }
            </div>
          </div>
          <div class="field narrower">
            <div class="label" i18n="transaction.eta|Transaction ETA">ETA</div>
            <div class="value">
              <span class="justify-content-end d-flex align-items-center">
                @if (mempoolPosition?.block >= 7) {
                  <span i18n="transaction.eta.in-several-hours|Transaction ETA in several hours or more">In several hours (or more)</span>
                } @else {
                  <app-time kind="until" *ngIf="(da$ | async) as da;" [time]="da.adjustedTimeAvg * (mempoolPosition.block + 1) + now + da.timeOffset" [fastRender]="false" [fixedRender]="true"></app-time>
                }
                @if (!showAccelerationSummary && isMobile && paymentType === 'cashapp' && accelerationEligible && !tx.acceleration && acceleratorAvailable && accelerateCtaType === 'button' && !tx?.acceleration) {
                  <a class="btn btn-sm accelerate btn-small-height" i18n="transaction.accelerate|Accelerate button label" (click)="onAccelerateClicked()">Accelerate</a>
                }
              </span>
            </div>
          </div>
        } @else if (tx && tx.status?.confirmed) {
          <div class="field narrower mt-2">
            <div class="label" i18n="transaction.confirmed-at">Confirmed at</div>
            <div class="value">
              &lrm;{{ tx.status.block_time * 1000 | date:'yyyy-MM-dd HH:mm' }}
              <div class="lg-inline">
                <i class="symbol">(<app-time kind="since" [time]="tx.status.block_time" [fastRender]="true" [showTooltip]="true"></app-time>)</i>
              </div>
            </div>
          </div>
          <div class="field narrower">
            <div class="label" i18n="transaction.block-height">Block height</div>
            <div class="value">
              <span class="justify-content-end d-flex align-items-center">
                <a [routerLink]="['/block/' | relativeUrl, tx.status?.block_height]" class="block-link">{{ tx.status?.block_height }}</a>
                <div class="confirmations">
                  <app-confirmations
                    *ngIf="tx"
                    [chainTip]="latestBlock?.height"
                    [height]="tx?.status?.block_height"
                  ></app-confirmations>
                </div>
              </span>
            </div>
          </div>
        }
      </div>
    </div>

    <div class="bottom-panel">
      @if (showAccelerationSummary && !accelerationFlowCompleted) {
        <app-accelerate-checkout *ngIf="(da$ | async) as da;" [txid]="tx.txid" [eta]="mempoolPosition?.block >= 7 ? null : da.adjustedTimeAvg * (mempoolPosition.block + 1) + now + da.timeOffset" (close)="accelerationFlowCompleted = true" [scrollEvent]="scrollIntoAccelPreview" class="h-100 w-100"></app-accelerate-checkout>
      } @else {
        @if (tx?.acceleration && !tx.status?.confirmed) {
          <div class="progress-icon">
            <fa-icon [icon]="['fas', 'wand-magic-sparkles']" [fixedWidth]="true"></fa-icon>
          </div>
          <span class="explainer" i18n="tracker.explain.accelerated">Your transaction has been accelerated</span>
        } @else {
          @switch (trackerStage) {
            @case ('waiting') {
              <div class="progress-icon">
                <div class="spinner-border text-light" style="width: 1em; height: 1em"></div>
              </div>
              <span class="explainer" i18n="tracker.explain.waiting">Waiting for your transaction to appear in the mempool</span>
            }
            @case ('pending') {
              <div class="progress-icon">
                <fa-icon [icon]="['fas', 'hourglass-start']" [fixedWidth]="true"></fa-icon>
              </div>
              <span class="explainer" i18n="tracker.explain.pending">Your transaction is in the mempool, but it will not be confirmed for some time.</span>
            }
            @case ('soon') {
              <div class="progress-icon">
                <fa-icon [icon]="['fas', 'hourglass-half']" [fixedWidth]="true"></fa-icon>
              </div>
              <span class="explainer" i18n="tracker.explain.soon">Your transaction is near the top of the mempool, and is expected to confirm soon.</span>
            }
            @case ('next') {
              <div class="progress-icon">
                <fa-icon [icon]="['fas', 'hourglass-end']" [fixedWidth]="true"></fa-icon>
              </div>
              <span class="explainer" i18n="tracker.explain.next-block">Your transaction is expected to confirm in the next block</span>
            }
            @case ('confirmed') {
              <div class="progress-icon">
                <fa-icon [icon]="['fas', 'circle-check']" [fixedWidth]="true"></fa-icon>
              </div>
              <span class="explainer" i18n="tracker.explain.confirmed">Your transaction is confirmed!</span>
            }
            @case ('replaced') {
              <div class="progress-icon">
                <fa-icon [icon]="['fas', 'timeline']" [fixedWidth]="true"></fa-icon>
              </div>
              <span class="explainer" i18n="tracker.explain.replaced">Your transaction has been replaced by a newer version!</span>
            }
          }
        }
      }
    </div>

    <div class="footer-link" [routerLink]="['/tx' | relativeUrl, tx?.txid]">
      <span>See more details <fa-icon [icon]="['fas', 'arrow-alt-circle-right']"></fa-icon></span>
    </div>
  </div>
</div>