<div class="stats-wrapper" *ngIf="accelerationStats$ | async as stats; else loading">
  <div class="stats-container">
    <div class="item">
      <h5 class="card-title" i18n="accelerator.requests">Requests</h5>
      <div class="card-text">
        <div>{{ stats.totalRequested }}</div>
        <div class="symbol" i18n="accelerator.total-accelerated">accelerated</div>
      </div>
    </div>
    <div class="item">
      <h5 class="card-title" i18n="accelerator.total-boost">Total Bid Boost</h5>
      <div class="card-text">
        <div>{{ stats.totalBidBoost / 100_000_000 | amountShortener: 4 }} <span class="symbol" i18n="shared.btc|BTC">BTC</span></div>
        <span class="fiat">
          <app-fiat [value]="stats.totalBidBoost"></app-fiat>
        </span>
      </div>
    </div>
    <div class="item">
      <h5 class="card-title" i18n="accelerator.total-vsize">Total vSize</h5>
      <div class="card-text">
        <div [innerHTML]="'&lrm;' + (stats.totalVsize | vbytes: 2)"></div>
        <div class="symbol">{{ (stats.totalVsize / (1_000_000 * blocksInPeriod) * 100).toFixed(2) }}% <span i18n="accelerator.percent-of-blocks"> of blocks</span></div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="stats-container loading-container">
    <div class="item">
      <h5 class="card-title" i18n="accelerator.requests">Requests</h5>
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
    <div class="item">
      <h5 class="card-title" i18n="accelerator.total-boost">Total Bid Boost</h5>
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
    <div class="item">
      <h5 class="card-title" i18n="accelerator.total-vsize">Total vSize</h5>
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
  </div>
</ng-template>
