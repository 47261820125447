<div infiniteScroll [alwaysCallback]="true" [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">

<ng-container *ngFor="let tx of transactions; let i = index; trackBy: trackByFn">
  <div *ngIf="!transactionPage" class="header-bg box tx-page-container">
    <a class="tx-link" [routerLink]="['/tx/' | relativeUrl, tx.txid]">
      <app-truncate [text]="tx.txid"></app-truncate>
    </a>
    <div>
      <ng-template [ngIf]="tx.status.confirmed">&lrm;{{ tx.status.block_time * 1000 | date:'yyyy-MM-dd HH:mm' }}</ng-template>
      <ng-template [ngIf]="!tx.status.confirmed && tx.firstSeen">
        <i><app-time kind="since" [time]="tx.firstSeen" [fastRender]="true" [showTooltip]="true"></app-time></i>
      </ng-template>
    </div>
  </div>

  <div class="header-bg box" [attr.data-cy]="'tx-' + i">

    <div *ngIf="errorUnblinded" class="error-unblinded">{{ errorUnblinded }}</div>
    <div class="row">
      <div class="col">
        <table class="table table-fixed table-borderless smaller-text table-sm table-tx-vin">
          <tbody>
            <ng-template ngFor let-vin let-vindex="index" [ngForOf]="tx.vin.slice(0, getVinLimit(tx))" [ngForTrackBy]="trackByIndexFn">
              <tr [ngClass]="{
                'assetBox': (assetsMinimal && vin.prevout && assetsMinimal[vin.prevout.asset] && !vin.is_coinbase && vin.prevout.scriptpubkey_address && tx._unblinded) || inputIndex === vindex,
                'highlight': this.address !== '' && (vin.prevout?.scriptpubkey_address === this.address || (vin.prevout?.scriptpubkey_type === 'p2pk' && vin.prevout?.scriptpubkey.slice(2, -2) === this.address))
              }">
                <td class="arrow-td">
                  <ng-template [ngIf]="vin.prevout === null && !vin.is_pegin" [ngIfElse]="hasPrevout">
                    <span class="grey">
                      <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" [fixedWidth]="true"></fa-icon>
                    </span>
                  </ng-template>
                  <ng-template #hasPrevout>
                    <ng-template [ngIf]="vin.is_pegin" [ngIfElse]="defaultPrevout">
                      <a target="_blank" *ngIf="stateService.env.BASE_MODULE === 'liquid'; else localPegInLink" [attr.href]="'https://mempool.space/tx/' + vin.txid + ':' + vin.vout" class="red">
                        <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" [fixedWidth]="true"></fa-icon>
                      </a>
                      <ng-template #localPegInLink>
                        <a [routerLink]="['/tx/', vin.txid]" class="red">
                          <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" [fixedWidth]="true"></fa-icon>
                        </a>
                      </ng-template>
                    </ng-template>
                    <ng-template #defaultPrevout>
                      <a [routerLink]="['/tx/' | relativeUrl, vin.txid]" [fragment]="'vout=' + vin.vout" class="red">
                        <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" [fixedWidth]="true"></fa-icon>
                      </a>
                    </ng-template>
                  </ng-template>
                </td>
                <td class="address-cell">
                  <div [ngSwitch]="true">
                    <ng-container *ngSwitchCase="vin.is_coinbase"><span i18n="transactions-list.coinbase">Coinbase</span><ng-template [ngIf]="network !== 'liquid' && network !== 'liquidtestnet'">&nbsp;<span i18n="transactions-list.newly-generated-coins">(Newly Generated Coins)</span></ng-template><br /><a placement="bottom" [ngbTooltip]="vin.scriptsig | hex2ascii"><span class="badge badge-secondary scriptmessage longer">{{ vin.scriptsig | hex2ascii }}</span></a></ng-container>
                    <ng-container *ngSwitchCase="vin.is_pegin">
                      <span i18n="transactions-list.peg-in">Peg-in</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="vin.prevout && vin.prevout.scriptpubkey_type === 'p2pk'">
                      <span>P2PK <a class="address p2pk-address" [routerLink]="['/address/' | relativeUrl, vin.prevout.scriptpubkey.slice(2, -2)]" title="{{ vin.prevout.scriptpubkey.slice(2, -2) }}">
                        <app-truncate [text]="vin.prevout.scriptpubkey.slice(2, -2)" [lastChars]="8"></app-truncate>
                      </a></span>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <ng-template [ngIf]="!vin.prevout" [ngIfElse]="defaultAddress">
                        <span *ngIf="vin.lazy; else defaultNoPrevout" class="skeleton-loader"></span>
                        <ng-template #defaultNoPrevout>
                          <span>{{ vin.issuance ? 'Issuance' : 'UNKNOWN' }}</span>
                        </ng-template>
                      </ng-template>
                      <ng-template #defaultAddress>
                        <a class="address" *ngIf="vin.prevout.scriptpubkey_address; else vinScriptPubkeyType" [routerLink]="['/address/' | relativeUrl, vin.prevout.scriptpubkey_address]" title="{{ vin.prevout.scriptpubkey_address }}">
                          <app-truncate [text]="vin.prevout.scriptpubkey_address" [lastChars]="8"></app-truncate>
                        </a>
                        <ng-template #vinScriptPubkeyType>
                          {{ vin.prevout.scriptpubkey_type?.toUpperCase() }}
                        </ng-template>
                        <div>
                          <app-address-labels [vin]="vin" [channel]="tx._channels && tx._channels.inputs[vindex] ? tx._channels.inputs[vindex] : null"></app-address-labels>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                </td>
                <td class="text-right nowrap amount" [class]="{large: vin?.prevout?.value > 1000000000}">
                  <ng-template [ngIf]="vin.prevout && vin.prevout.asset && vin.prevout.asset !== nativeAssetId" [ngIfElse]="defaultOutput">
                    <div *ngIf="assetsMinimal && assetsMinimal[vin.prevout.asset] else assetVinNotFound">
                      <ng-container *ngTemplateOutlet="assetBox; context:{ $implicit: vin.prevout }"></ng-container>
                    </div>
                    <ng-template #assetVinNotFound>
                      {{ vin.prevout.value }} <a [routerLink]="['/assets/asset/' | relativeUrl, vin.prevout.asset]">{{ vin.prevout.asset | slice : 0 : 7 }}</a>
                    </ng-template>
                  </ng-template>
                  <ng-template #defaultOutput>
                    <span *ngIf="vin.lazy" class="skeleton-loader"></span>
                    <app-amount [blockConversion]="tx.price" *ngIf="vin.prevout" [satoshis]="vin.prevout.value"></app-amount>
                  </ng-template>
                </td>
              </tr>
              <tr *ngIf="(showDetails$ | async) === true">
                <td colspan="3" class="details-container" >
                  <table class="table table-striped table-fixed table-borderless details-table mb-3">
                    <tbody>
                      <ng-template [ngIf]="vin.scriptsig">
                        <tr>
                          <td i18n="transactions-list.scriptsig.asm|ScriptSig (ASM)">ScriptSig (ASM)</td>
                          <td style="text-align: left;" [innerHTML]="vin.scriptsig_asm | asmStyler"></td>
                        </tr>
                        <tr>
                          <td i18n="transactions-list.scriptsig.hex|ScriptSig (HEX)">ScriptSig (HEX)</td>
                          <td style="text-align: left;">{{ vin.scriptsig }}</td>
                        </tr>
                      </ng-template>
                      <tr *ngIf="vin.witness" class="vin-witness">
                        <td i18n="transactions-list.witness">Witness</td>
                        <td style="text-align: left;">
                          <ng-container *ngFor="let witness of vin.witness; index as i">
                            <p class="witness-item">
                              @if (witness.length > 1000) {
                                @if (!showFullWitness[vindex][i]) {
                                  {{ witness | slice:0:1000 }}
                                } @else {
                                  {{ witness }}
                                }
                              } @else if (witness) {
                                {{ witness }}
                              } @else {
                                &lt;empty&gt;
                              }
                            </p>
                            @if (witness.length > 1000) {
                              <div style="display: flex;">
                                @if (!showFullWitness[vindex][i]) {
                                  <span>...</span>
                                }
                                <label class="btn btn-sm btn-primary mt-2" (click)="toggleShowFullWitness(vindex, i)" style="margin-left: auto;">
                                  @if (!showFullWitness[vindex][i]) {
                                    <span i18n="show-all">Show all</span>
                                  } @else {
                                    <span i18n="show-less">Show less</span>
                                  }
                                </label>
                              </div>
                            }
                          </ng-container>
                        </td>
                      </tr>
                      <tr *ngIf="vin.inner_redeemscript_asm">
                        <td i18n="transactions-list.p2sh-redeem-script">P2SH redeem script</td>
                        <td style="text-align: left;" [innerHTML]="vin.inner_redeemscript_asm | asmStyler"></td>
                      </tr>
                      <tr *ngIf="vin.inner_witnessscript_asm">
                        <td *ngIf="vin.prevout && vin.prevout.scriptpubkey_type == 'v1_p2tr'; else p2wsh" i18n="transactions-list.p2tr-tapscript">P2TR tapscript</td>
                        <ng-template #p2wsh>
                          <td i18n="transactions-list.p2wsh-witness-script">P2WSH witness script</td>
                        </ng-template>
                        <td style="text-align: left;">
                          <div [innerHTML]="vin.inner_witnessscript_asm | asmStyler:showFullScript[vindex]"></div>
                          <div *ngIf="vin.inner_witnessscript_asm.length > 1000" style="display: flex;">
                            <span *ngIf="!showFullScript[vindex]">...</span>
                            <label class="btn btn-sm btn-primary mt-2" (click)="toggleShowFullScript(vindex)" style="margin-left: auto;">
                              <span *ngIf="!showFullScript[vindex]" i18n="show-all">Show all</span>
                              <span *ngIf="showFullScript[vindex]" i18n="show-less">Show less</span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td i18n="transactions-list.nsequence">nSequence</td>
                        <td style="text-align: left;">{{ formatHex(vin.sequence) }}</td>
                      </tr>
                      <ng-template [ngIf]="vin.prevout">
                        <tr>
                          <td i18n="transactions-list.previous-output-script">Previous output script</td>
                          <td style="text-align: left;" [innerHTML]="vin.prevout.scriptpubkey_asm | asmStyler"></td>
                        </tr>
                        <tr>
                          <td i18n="transactions-list.previous-output-type">Previous output type</td>
                          <td style="text-align: left;">{{ vin.prevout.scriptpubkey_type?.toUpperCase() }}</td>
                        </tr>
                    </ng-template>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-template>
            <tr *ngIf="tx.vin.length > getVinLimit(tx)">
              <td colspan="3" class="text-center">
                <button class="btn btn-sm btn-primary mt-2" (click)="showMoreInputs(tx)">
                  <span *ngIf="getVinLimit(tx, true) >= tx.vin.length; else showMoreInputsLabel" i18n="show-all">Show all</span>
                  <ng-template #showMoreInputsLabel>
                    <span i18n="show-more">Show more</span>
                  </ng-template>
                  (<ng-container *ngTemplateOutlet="xRemaining; context: {$implicit: tx.vin.length - getVinLimit(tx)}"></ng-container>)
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-100 d-block d-md-none"></div>
      <div class="col mobile-bottomcol">
        <table class="table table-fixed table-borderless smaller-text table-sm table-tx-vout">
          <tbody>
            <ng-template ngFor let-vout let-vindex="index" [ngForOf]="tx.vout.slice(0, getVoutLimit(tx))" [ngForTrackBy]="trackByIndexFn">
              <tr [ngClass]="{
                'assetBox': assetsMinimal && assetsMinimal[vout.asset] && vout.scriptpubkey_address && tx.vin && !tx.vin[0].is_coinbase && tx._unblinded || outputIndex === vindex,
                'highlight': this.address !== '' && (vout.scriptpubkey_address === this.address || (vout.scriptpubkey_type === 'p2pk' && vout.scriptpubkey.slice(2, -2) === this.address))
              }">
                <td class="address-cell">
                  <a class="address" *ngIf="vout.scriptpubkey_address; else pubkey_type" [routerLink]="['/address/' | relativeUrl, vout.scriptpubkey_address]" title="{{ vout.scriptpubkey_address }}">
                    <app-truncate [text]="vout.scriptpubkey_address" [lastChars]="8"></app-truncate>
                  </a>
                  <ng-template #pubkey_type>
                    <ng-container *ngIf="vout.scriptpubkey_type === 'p2pk'; else scriptpubkey_type">
                      P2PK <a class="address p2pk-address" [routerLink]="['/address/' | relativeUrl, vout.scriptpubkey.slice(2, -2)]" title="{{ vout.scriptpubkey.slice(2, -2) }}">
                        <app-truncate [text]="vout.scriptpubkey.slice(2, -2)" [lastChars]="8"></app-truncate>
                      </a>
                    </ng-container>
                  </ng-template>
                  <div>
                    <app-address-labels [vout]="vout" [channel]="tx._channels && tx._channels.outputs[vindex] ? tx._channels.outputs[vindex] : null"></app-address-labels>
                  </div>
                  <ng-template #scriptpubkey_type>
                    <ng-template [ngIf]="vout.pegout" [ngIfElse]="defaultscriptpubkey_type">
                      <ng-container i18n="transactions-list.peg-out-to">Peg-out to <ng-container *ngTemplateOutlet="pegOutLink"></ng-container></ng-container>
                      <ng-template #pegOutLink>
                        <a *ngIf="stateService.env.BASE_MODULE === 'liquid'; else localPegoutLink" target="_blank" style="color:var(--orange)" [attr.href]="'https://mempool.space/address/' + vout.pegout.scriptpubkey_address" title="{{ vout.pegout.scriptpubkey_address }}">
                          <app-truncate [text]="vout.pegout.scriptpubkey_address"></app-truncate>
                        </a>
                        <ng-template #localPegoutLink>
                          <a [routerLink]="['/address/', vout.pegout.scriptpubkey_address]" title="{{ vout.pegout.scriptpubkey_address }}">
                            <app-truncate [text]="vout.pegout.scriptpubkey_address"></app-truncate>
                          </a>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                    <ng-template #defaultscriptpubkey_type>
                      <ng-template [ngIf]="vout.scriptpubkey_type === 'op_return'" [ngIfElse]="otherPubkeyType">
                        OP_RETURN&nbsp;<a placement="bottom" [ngbTooltip]="vout.scriptpubkey_asm | hex2ascii"><span *ngIf="vout.scriptpubkey_asm !== 'OP_RETURN'" class="badge badge-secondary scriptmessage">{{ vout.scriptpubkey_asm | hex2ascii }}</span></a>
                      </ng-template>
                      <ng-template #otherPubkeyType>{{ vout.scriptpubkey_type | scriptpubkeyType }}</ng-template>
                    </ng-template>
                  </ng-template>
                </td>
                <td class="text-right nowrap amount" [class]="{large: vout?.value > 1000000000}">
                  <ng-template [ngIf]="vout.asset && vout.asset !== nativeAssetId" [ngIfElse]="defaultOutput">
                    <div *ngIf="assetsMinimal && assetsMinimal[vout.asset] else assetNotFound">
                      <ng-container *ngTemplateOutlet="assetBox; context:{ $implicit: vout }"></ng-container>
                    </div>
                    <ng-template #assetNotFound>
                      {{ vout.value }} <a [routerLink]="['/assets/asset/' | relativeUrl, vout.asset]">{{ vout.asset | slice : 0 : 7 }}</a>
                    </ng-template>
                  </ng-template>
                  <ng-template #defaultOutput>
                    <app-amount [blockConversion]="tx.price" [satoshis]="vout.value"></app-amount>
                  </ng-template>
                </td>
                <td class="arrow-td">
                  <span *ngIf="!tx._outspends || vout.scriptpubkey_type === 'op_return' || vout.scriptpubkey_type === 'fee' ; else outspend" class="grey">
                    <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" [fixedWidth]="true"></fa-icon>
                  </span>
                  <ng-template #outspend>
                    <span *ngIf="!tx._outspends[vindex] || !tx._outspends[vindex].spent; else spent" class="green">
                      <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" [fixedWidth]="true"></fa-icon>
                    </span>
                    <ng-template #spent>
                      <a *ngIf="tx._outspends[vindex].txid else outputNoTxId" [routerLink]="['/tx/' | relativeUrl, tx._outspends[vindex].txid]"  [fragment]="'vin=' + tx._outspends[vindex].vin" class="red">
                        <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" [fixedWidth]="true"></fa-icon>
                      </a>
                      <ng-template #outputNoTxId>
                        <span class="red">
                          <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" [fixedWidth]="true"></fa-icon>
                        </span>
                      </ng-template>
                    </ng-template>
                  </ng-template>
                </td>
              </tr>
              <tr *ngIf="(showDetails$ | async) === true">
                <td colspan="3" class=" details-container" >
                  <table class="table table-striped table-borderless details-table mb-3">
                    <tbody>
                      <tr>
                        <td i18n="transactions-list.scriptpubkey.asm|ScriptPubKey (ASM)">ScriptPubKey (ASM)</td>
                        <td style="text-align: left;" [innerHTML]="vout.scriptpubkey_asm | asmStyler"></td>
                      </tr>
                      <tr>
                        <td i18n="transactions-list.scriptpubkey.hex|ScriptPubKey (HEX)">ScriptPubKey (HEX)</td>
                        <td style="text-align: left;">{{ vout.scriptpubkey }}</td>
                      </tr>
                      <tr *ngIf="vout.scriptpubkey_type == 'op_return'">
                        <td>OP_RETURN <span>data</span></td>
                        <td style="text-align: left;">{{ vout.scriptpubkey_asm | hex2ascii }}</td>
                      </tr>
                      <tr *ngIf="vout.scriptpubkey_type">
                        <td i18n="transactions-list.vout.scriptpubkey-type">Type</td>
                        <td style="text-align: left;">{{ vout.scriptpubkey_type.toUpperCase() }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-template>
            <tr *ngIf="tx.vout.length > getVoutLimit(tx)">
              <td colspan="3" class="text-center">
                <button class="btn btn-sm btn-primary mt-2" (click)="showMoreOutputs(tx)">
                  <span *ngIf="getVoutLimit(tx, true) >= tx.vout.length; else showMoreOutputsLabel" i18n="show-all">Show all</span>
                  <ng-template #showMoreOutputsLabel>
                    <span i18n="show-more">Show more</span>
                  </ng-template>
                  (<ng-container *ngTemplateOutlet="xRemaining; context: {$implicit: tx.vout.length - getVoutLimit(tx)}"></ng-container>)
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="summary">
      <div class="float-left mt-2-5" *ngIf="!transactionPage && !tx.vin[0].is_coinbase && tx.fee !== -1">
        <app-fee-rate [fee]="tx.fee" [weight]="tx.weight"></app-fee-rate>
        <span class="d-none d-sm-inline-block">&nbsp;&ndash; {{ tx.fee | number }} <span class="symbol"
            i18n="shared.sat|sat">sat</span> <span class="fiat"><app-fiat [blockConversion]="tx.price" [value]="tx.fee"></app-fiat></span></span>
      </div>
      <div class="float-left mt-2-5 grey-info-text" *ngIf="tx.fee === -1" i18n="transactions-list.load-to-reveal-fee-info">Show more inputs to reveal fee data</div>

      <div class="float-right">
        <ng-container *ngIf="showConfirmations && latestBlock$ | async as latestBlock">
          <app-confirmations [chainTip]="latestBlock?.height" [height]="tx?.status?.block_height" buttonClass="mt-2"></app-confirmations>
        </ng-container>
        <button *ngIf="address === ''; else viewingAddress" type="button" class="btn btn-sm btn-primary mt-2 ml-2" (click)="switchCurrency()">
          <ng-template [ngIf]="(network === 'liquid' || network === 'liquidtestnet') && haveBlindedOutputValues(tx)" [ngIfElse]="defaultAmount" i18n="shared.confidential">Confidential</ng-template>
          <ng-template #defaultAmount>
            <app-amount [blockConversion]="tx.price" [satoshis]="getTotalTxOutput(tx)"></app-amount>
          </ng-template>
        </button>
        <ng-template #viewingAddress>
          <button type="button" class="btn btn-sm mt-2 ml-2" (click)="switchCurrency()" [ngClass]="{'btn-success': tx['addressValue'] >= 0, 'btn-danger': tx['addressValue'] < 0}">
            <app-amount [blockConversion]="tx.price" [satoshis]="tx['addressValue']" [addPlus]="true"></app-amount>
          </button>
        </ng-template>
      </div>
      <div class="clearfix"></div>
    </div>

  </div>

  <br />

</ng-container>

</div>

<ng-template #assetBox let-item>
  {{ item.value / pow(10, assetsMinimal[item.asset][3]) | number: '1.' + assetsMinimal[item.asset][3] + '-' + assetsMinimal[item.asset][3] }} {{ assetsMinimal[item.asset][1] }}
  <br />
  {{ assetsMinimal[item.asset][0] }}
  <br />
  <a [routerLink]="['/assets/asset/' | relativeUrl, item.asset]">{{ item.asset | shortenString : 13 }}</a>
</ng-template>

<ng-template #xRemaining let-x i18n="x-remaining">{{ x }} remaining</ng-template>
