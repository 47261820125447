<ng-container *ngIf="!noFiat && (viewAmountMode$ | async) === 'fiat' && (conversions$ | async) as conversions; else viewFiatVin">
  <span class="fiat" *ngIf="blockConversion; else noblockconversion">
    {{ addPlus && satoshis >= 0 ? '+' : '' }}{{
      (
        (blockConversion.price[currency] > -1 ? blockConversion.price[currency] : null) ??
        (blockConversion.price['USD']    > -1 ? blockConversion.price['USD'] * blockConversion.exchangeRates['USD' + currency] : null) ?? 0
      ) * satoshis / 100000000 | fiatCurrency : digitsInfo : currency
    }}
  </span>
  <ng-template #noblockconversion>
    <span class="fiat" *ngIf="!forceBlockConversion; else zeroValue">{{ addPlus && satoshis >= 0 ? '+' : '' }}{{ (conversions[currency] > -1 ? conversions[currency] : 0) * satoshis / 100000000 | fiatCurrency : digitsInfo : currency }}
    </span>
  </ng-template>
  <ng-template #zeroValue>
    <span class="fiat">{{ 0 | fiatCurrency : digitsInfo : currency }}</span>
  </ng-template>
</ng-container>

  <ng-template #viewFiatVin>
  <ng-template [ngIf]="(network === 'liquid' || network === 'liquidtestnet') && (satoshis === undefined || satoshis === null)" [ngIfElse]="default">
    <span i18n="shared.confidential">Confidential</span>
  </ng-template>
  <ng-template #default>
    @if ((viewAmountMode$ | async) === 'btc' || (viewAmountMode$ | async) === 'fiat') {
      &lrm;{{ addPlus && satoshis >= 0 ? '+' : '' }}{{ satoshis / 100000000 | number : digitsInfo }}
      <span class="symbol">
        <ng-container *ngTemplateOutlet="prefix"></ng-container>BTC
      </span>
    } @else {
      @if (digitsInfo === '1.8-8') {
        &lrm;{{ addPlus && satoshis >= 0 ? '+' : '' }}{{ satoshis | number }}
      } @else { 
        &lrm;{{ addPlus && satoshis >= 0 ? '+' : '' }}{{ satoshis | amountShortener : satoshis < 1000 && satoshis > -1000 ? 0 : 1 }}
      }
      <span class="symbol">
        <ng-container *ngTemplateOutlet="prefix"></ng-container>sats
      </span>
    }
  </ng-template>
</ng-template>

<ng-template #prefix>
  <ng-template [ngIf]="network === 'liquid' && !forceBtc">L-</ng-template>
  <ng-template [ngIf]="network === 'liquidtestnet'">tL-</ng-template>
  <ng-template [ngIf]="network === 'testnet'">t</ng-template>
  <ng-template [ngIf]="network === 'testnet4'">t</ng-template>
  <ng-template [ngIf]="network === 'signet'">s</ng-template>
</ng-template>
